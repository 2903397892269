@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .headline {
    @apply text-xl font-bold mb-2;
  }

  .input {
    @apply border border-gray-300 bg-gray-100 hover:bg-white focus:bg-white hover:border-gray-200 transition-colors rounded-md shadow-sm px-2 py-1 outline-0 w-full
  }

  .button, .link {
    @apply bg-blue-300 transition-colors hover:bg-blue-500 px-3 py-2 rounded
  }

  .container-sm {
    @apply w-full px-4 lg:px-0 lg:w-[650px] mx-auto;
  }

  .card {
    @apply border border-gray-200 rounded-md shadow-md p-4 bg-white;
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

button[disabled],
a[disabled] {
  opacity: 0.2;
  pointer-events: none;
}

.react-tabs__tab--selected {
  background-color: #fff;
  border-radius: 4px;
  box-shadow:  rgba(0, 0, 0, 0) 0 0 0 0, rgba(0, 0, 0, 0) 0 0 0 0, rgba(0, 0, 0, 0.1) 0 4px 6px -1px, rgba(0, 0, 0, 0.1) 0 2px 4px -2px;
}
