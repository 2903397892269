body {
  background-color: #f1f2f3;
}

h1 {
  font-size: 3rem;
}

article h2 {
  font-size: 1.5rem;
  margin-bottom: 15px;
  margin-top: 25px;
}

article p {
  margin-bottom: 10px;
}

article img {
  margin: 0 auto;
  padding: 10px 0;
}

